import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function PageHeader(props) {
  return (
    <div className={'mx-auto max-w-screen-2xl w-full text-white relative'}>
      <div className={'absolute inset-0 z-0 w-full pointer-events-none flex'}>
        <StaticImage
          src="../images/header-bg.jpeg"
          alt="Contact"
          placeholder="blurred"
          className={'object-cover object-center w-full'}
          formats={["auto", "webp", "avif"]}
        />
      </div>
      <div className={'absolute z-0 inset-0 pointer-events-none bg-black/50'} />
      <div className={'relative text-center p-6 py-32'}>
        <h1 className={'text-3xl mb-2 uppercase'}>
          { props.title }
        </h1>
        <p className={'uppercase'}>{ props.breadcrumbs }</p>
      </div>
    </div>
  )
}
