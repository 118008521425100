import * as React from "react"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import { Offline, Online } from "react-detect-offline"

import { StaticImage } from "gatsby-plugin-image"

const ContactUsPage = () => {
  return (
    <Layout>
      <PageHeader title="Contact us" breadcrumbs="HOME /  CONTACT" />
      <main className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <div className={'lg:grid lg:grid-cols-2 lg:gap-16'}>
          <div>
            <div className={'lg:sticky lg:top-16 space-y-8'}>
              <div className={'prose-lg'}>
                <h3 className={'font-bold text-xl mb-4'}>SHOWROOM</h3>
                <p>2 Beechill Park Avenue<br />
                Belfast, BT8 7PR<br />
                <a href="mailto:info@joandrewsinteriors.com">info@joandrewsinteriors.com</a><br />
                028 9070 1383</p>
                <h3 className={'font-bold text-xl mb-4'}>OPENING HOURS</h3>
                <p>Monday – Saturday 10am – 5pm</p>
              </div>
            </div>
          </div>
          <div>
            <div className={'lg:sticky lg:top-16 space-y-8'}>
              <div>
                <StaticImage
                  src="../images/contact-us.jpeg"
                  alt="Contact"
                  className={'w-full block'}
                />
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className={'mx-auto max-w-screen-2xl w-full relative'}>
        <Online>
          <iframe
            loading="lazy"
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d18513.446046585705!2d-5.90593!3d54.54793900000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa51f341cad0200f8!2sJo%20Andrews%20Interiors!5e0!3m2!1sen!2suk!4v1665085064003!5m2!1sen!2suk"
            width="100%"
            height="100%"
            frameBorder="0"
            referrerPolicy="no-referrer-when-downgrade"
            className={'h-96'}
          ></iframe>
        </Online>
        <Offline>
          <div className={'flex items-center justify-center w-full bg-gray-200 h-96'}>
            You're offline right now. Check your connection.
          </div>
        </Offline>
      </div>

    </Layout>
  )
}

export default ContactUsPage

export const Head = () => (
    <>
    <title>Contact Us - Jo Andrews Interiors</title>
    <meta name="description" content="Jo Andrews Interior Designer Belfast. Belfast's leading Interior Design, Curtains, Upholstery &amp; Bespoke Furniture company." />
    </>
)
